
import { defineComponent, onMounted } from "vue";
import StatsWidget3 from "@/view/content/widgets/stats/Widget3.vue";
import StatsWidget4 from "@/view/content/widgets/stats/Widget4.vue";
import StatsWidget5 from "@/view/content/widgets/stats/Widget5.vue";
import StatsWidget6 from "@/view/content/widgets/stats/Widget6.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget8 from "@/view/content/widgets/stats/Widget8.vue";
import StatsWidget9 from "@/view/content/widgets/stats/Widget9.vue";
import TableWidget2 from "@/view/content/widgets/tables/Widget2.vue";
import StatsWidget1 from "@/view/content/widgets/stats/Widget1.vue";
import StatsWidget2 from "@/view/content/widgets/stats/Widget2.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "light",
  components: {
    StatsWidget3,
    StatsWidget4,
    StatsWidget5,
    StatsWidget6,
    StatsWidget7,
    StatsWidget8,
    StatsWidget9,
    TableWidget2,
    StatsWidget1,
    StatsWidget2,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboards", "Light");
    });
  },
});
